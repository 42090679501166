// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "bn_lC";
export var aiChat__animate = "bn_lr";
export var aiChat__animate_content = "bn_ls";
export var aiChat__answer = "bn_ly";
export var aiChat__content = "bn_lv";
export var aiChat__icon = "bn_lt";
export var aiChat__loadingState = "bn_lw";
export var aiChat__message = "bn_lq";
export var aiChat__question = "bn_lx";
export var aiChat__sender = "bn_lz";
export var aiChat__senderInfo = "bn_lB";
export var pulseSize = "bn_lm";